export const slots: { [key: number]: number } = {
  1: -2,
  2: 1,
  3: 2,
  4: 5,
  5: 10,
  6: 5,
  7: 2,
  8: 1,
  9: -2,
};
